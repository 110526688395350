import keycloak from './Keycloak';
import AppsApi from './util/AppsApi';
import UiReducer from './state/UiReducer';

const UserMode = {
  Anonymous: 'anonymous',
  User: 'user',
  Contributor: 'contributor',
  Owner: 'owner',
};

const getCurrentUser = () => keycloak.idTokenParsed?.email || 'anonymous';

const getAccessMode = (appJson) => {
  const user = getCurrentUser();
  if (appJson.owner && appJson.owner === user) {
    return UserMode.Owner;
  }

  if (appJson.contributors && appJson.contributors.includes(user)) {
    return UserMode.Contributor;
  }

  if (appJson.users && appJson.users.includes(user)) {
    return UserMode.User;
  }

  return UserMode.Anonymous;
};

// eslint-disable-next-line camelcase
const getRealmGroups = () => keycloak.idTokenParsed?.realm_groups || [];

const isCloudAdmin = () => getRealmGroups().includes('cloud-admins');

const fetchAccounts = async ({ dispatch, displayErrors = true, flags = ['SyncEnabled', 'RequestSync'] }) => {
  const response = await AppsApi.getAccountInfo({ flags });
  if (response.code >= 300) {
    console.log(response);
    dispatch(UiReducer.setGenericUiAttribute(UiReducer.Keys.AccountInfoServerError, response));
    if (displayErrors) {
      response.messages.unshift('Failed to load Account Data');
      dispatch(UiReducer.uiSetErrors(response.messages));
    }
  } else {
    dispatch(UiReducer.setGenericUiAttribute(UiReducer.Keys.AccountInfo, response));
    dispatch(UiReducer.setGenericUiAttribute(UiReducer.Keys.AccountInfoServerError, undefined));
  }
};

const getAssignedKey = (globalState) => {
  const accountInfo = UiReducer.getGenericUiAttribute(globalState, UiReducer.Keys.AccountInfo);

  if (!accountInfo) {
    return null;
  }

  const email = getCurrentUser();

  let assignedKey = null;

  accountInfo.secondaryAccounts.forEach((secondaryAccount) => {
    secondaryAccount.keys.forEach((key) => {
      if (key.assignments.indexOf(email) >= 0) {
        assignedKey = key;
      }
    });
  });

  accountInfo.primaryAccount.keys.forEach((key) => {
    if (key.assignments.indexOf(email) >= 0) {
      assignedKey = key;
    }
  });

  return assignedKey;
};

const getAssignedCreatorKey = (globalState) => {
  const key = getAssignedKey(globalState);
  if (key && key.allowsCreators) {
    return key;
  }
  return null;
};

const getEffectiveSubscriptionAccount = (globalState) => {
  const accountInfo = UiReducer.getGenericUiAttribute(globalState, UiReducer.Keys.AccountInfo);
  const email = getCurrentUser();

  let subscriptionAccount = null;
  accountInfo.secondaryAccounts.forEach((secondaryAccount) => {
    if (subscriptionAccount !== null) {
      return;
    }
    secondaryAccount.keys.forEach((key) => {
      if (subscriptionAccount !== null) {
        return;
      }
      if (!key.disabled && key.assignments.indexOf(email) >= 0) {
        subscriptionAccount = secondaryAccount;
      }
    });
  });

  if (subscriptionAccount === null) {
    subscriptionAccount = accountInfo.primaryAccount;
  }

  return subscriptionAccount;
};

const isActiveCreatorAccount = (globalState) => {
  if (!UiReducer.getGenericUiAttribute(globalState, UiReducer.Keys.AccountInfo)) {
    return false;
  }
  const assignedKey = getAssignedCreatorKey(globalState);
  if (!assignedKey?.disabled && assignedKey?.allowsCreators) {
    return true;
  }
  return false;
};

const isAssignedActiveKey = (globalState) => {
  const assignedKey = getAssignedKey(globalState);
  return assignedKey && !assignedKey.disabled;
};

const getAvailableKeys = (globalState) => {
  const keys = [];
  const accountInfo = UiReducer.getGenericUiAttribute(globalState, UiReducer.Keys.AccountInfo);
  if (!accountInfo || !accountInfo.primaryAccount) {
    return keys;
  }

  accountInfo.primaryAccount.keys.forEach((key) => {
    const copyKey = { ...key };
    if (copyKey.assignments.indexOf(accountInfo.primaryAccount.email) >= 0) {
      copyKey.assigned = true;
    }
    keys.push(copyKey);
  });

  accountInfo.secondaryAccounts.forEach((secondaryAccount) => {
    secondaryAccount.keys.forEach((key) => {
      const copyKey = { ...key };
      copyKey.disabled = true;
      copyKey.granted = true;
      copyKey.owner = secondaryAccount.email;
      keys.push(copyKey);
    });
  });

  return keys;
};

const getEnabledFeatures = (globalState) => {
  if (!UiReducer.getGenericUiAttribute(globalState, UiReducer.Keys.AccountInfo)) {
    return [];
  }
  return getEffectiveSubscriptionAccount(globalState).subscription.featuresEnabled;
};

const getAccessDomains = (globalState) => {
  const { subscription } = getEffectiveSubscriptionAccount(globalState);
  return subscription.endUserAssignmentRules?.filter((el) => el.type === 'domain').map((el) => el.target) || [];
};


export default {
  UserMode,
  getCurrentUser,
  getAccessMode,
  getRealmGroups,
  isCloudAdmin,
  fetchAccounts,
  getAssignedKey,
  getAssignedCreatorKey,
  getEffectiveSubscriptionAccount,
  isActiveCreatorAccount,
  getAvailableKeys,
  getEnabledFeatures,
  isAssignedActiveKey,
  getAccessDomains,
};
